// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState, useRef } from "react";
import { FaPlay, FaPause, FaStop, FaVolumeMute, FaVolumeUp } from "react-icons/fa";
import styles from "./syntheticAIPage.module.css";

const SyntheticAIPage = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [question, setQuestion] = useState("");
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [volume, setVolume] = useState(100);
  const [isMuted, setIsMuted] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  useEffect(() => {
    if (!isMobile) {
      const script = document.createElement("script");
      script.src = `synthetic_ai.min.js`;
      script.setAttribute("data-param", "tlk_WjM82BRJyf3H_CJS3h5BF");
      script.async = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }

    return undefined;
  }, [isMobile]);

  const handleGenerate = async () => {
    if (!question.trim()) return;

    setLoading(true);
    setResponse(null);
    setVideoUrl("");

    try {
      const requestBody = {
        question,
        syntheticUUID: "",
        userIp: "",
        isUseExternal: true,
      };

      const res = await fetch("https://testing.aioiva.com/api/v1/synthetic/chat/generate-video", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      const data = await res.json();
      setResponse(data);
      if (data.videoUrl) {
        setVideoUrl(data.videoUrl);
      }
    } catch (error) {
      setResponse({ error: "Error." });
    } finally {
      setLoading(false);
    }
  };

  const handlePlay = () => videoRef.current?.play();
  const handlePause = () => videoRef.current?.pause();
  const handleStop = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  };

  const handleMuteToggle = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setIsMuted(videoRef.current.muted);
    }
  };

  const handleVolumeChange = (e) => {
    const newVolume = e.target.value;
    setVolume(newVolume);
    if (videoRef.current) {
      videoRef.current.volume = newVolume / 100;
    }
    setIsMuted(newVolume === "0");
  };

  if (isMobile) {
    return (
      <div className={styles.pageBox}>
        <div className={styles.mainBody}>
          <div className={styles.mainContainer}>
            <h2>Access restricted</h2>
            <p>The Synthetic AI video bot is available only on desktop.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.pageBox}>
      <div className={styles.mainBody}>
        <div className={styles.mainContainer}>
          <h2 className={styles.header}>Synthetic AI video bot</h2>

          <div className={styles.botSection}>
            <div className={styles.videoContainer}>
              {videoUrl ? (
                <video ref={videoRef} className={styles.video} src={videoUrl} autoPlay playsInline />
              ) : (
                <div className={styles.placeholder}>Synthetic AI</div>
              )}
            </div>

            <div className={styles.videoControls}>
              <div className={styles.controlButtons}>
                <button onClick={handlePlay} className={styles.controlButton}>
                  <FaPlay />
                </button>
                <button onClick={handlePause} className={styles.controlButton}>
                  <FaPause />
                </button>
                <button onClick={handleStop} className={styles.controlButton}>
                  <FaStop />
                </button>
                <button onClick={handleMuteToggle} className={styles.controlButton}>
                  {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
                </button>
              </div>

              <div className={styles.volumeControl}>
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={volume}
                  onChange={handleVolumeChange}
                  className={styles.volumeSlider}
                />
              </div>
            </div>

            <div className={styles.inputSection}>
              <input
                type="text"
                placeholder="Enter your question here..."
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                className={styles.input}
              />
              <div className={styles.noteSection}>
                <strong>Note:</strong> This is a **test server**, and processing power is **limited**.
                <br />
                Generation may take some time, so please be patient and avoid very long questions! 😊
              </div>
              <button onClick={handleGenerate} className={styles.button} disabled={loading}>
                {loading ? "Generating..." : "Generate"}
              </button>
            </div>
          </div>

          <div className={styles.videoLink}>
            <h4>Generated video link:</h4>
            {videoUrl ? (
              <a href={videoUrl} target="_blank" rel="noopener noreferrer">
                {videoUrl}
              </a>
            ) : (
              <p className={styles.placeholder}>No video generated yet</p>
            )}
          </div>

          <div className={styles.apiSection}>
            <h3>API Usage</h3>
            <p>
              To generate a Synthetic AI video, send a <strong>POST</strong> request to:
            </p>
            <code className={styles.apiEndpoint}>
              POST https://testing.aioiva.com/api/v1/synthetic/chat/generate-video
            </code>

            <h4>Request Body:</h4>
            <pre className={styles.codeBlock}>
              {JSON.stringify(
                {
                  question: "Your question here",
                  syntheticUUID: "",
                  userIp: "",
                  isUseExternal: true,
                },
                null,
                2
              )}
            </pre>

            <div className={styles.responseBlock}>
              <h4>Example Response:</h4>
              <pre className={styles.codeBlock}>
                {`{
  "videoUrl": "https://example.com/generated_video.mp4"
}`}
              </pre>
            </div>
          </div>
        </div>
      </div>
      <h6 className={styles.hint}>
        Press Ctrl + Shift + R (Windows) or Command + Shift + R (Mac) to force a restart and clear the cache.
      </h6>
    </div>
  );
};

export default SyntheticAIPage;
